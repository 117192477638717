// React and routing
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

// Styles
import "react-datepicker/dist/react-datepicker.css";
import "./list.css";
import "@sendbird/uikit-react/dist/index.css";

// Utilities and configurations
import FastAPIClient from "../../client";
import config from "../../config";

// Internationalization
import { FormattedMessage, IntlProvider } from "react-intl";
import messages_en from "./translations/en.json";
import messages_fr from "./translations/fr.json";

// Components
import Product from "../../components/LayoutComponents/Product";
import List from "../../components/ListComponents/Listelement/List";
import CustomerDetails from "../../components/LayoutComponents/CustomerDetails/CustomerDetails";
import Loader from "../../components/Loader";
import CollapseComponent from "../../components/CollapseComponents/CollapseComponent";
import BackButton from "../../components/Buttons/BackButton";
import CustomizedApp from "../../components/Sendbird/CustomizedApp";
import OrderHistory from "../../components/LayoutComponents/OrderHistory";
import VisitHistory from "../../components/LayoutComponents/VisitHistory";
import ClientOrders from "../../components/LayoutComponents/ClientOrders";
import ReportForm from "../../components/LayoutComponents/ReportForm";
import ClientStatistics from "../../components/LayoutComponents/ClientStatistics";
import CreateMeetingModal from "../../components/ModalComponents/CreateMeetingModal";
import ReminderModal from "../../components/ModalComponents/ReminderModal";
import { useOfflineMeetings } from '../../components/OfflineContext'

// SendBird
import { SendBirdProvider as Sendbird } from "@sendbird/uikit-react";

// Icons
import {
    FiBarChart2,
    FiBookOpen,
    FiEdit,
    FiShoppingBag,
    FiShoppingCart,
    FiShuffle,
    FiUsers,
    FiFileText,
    FiAlertTriangle,
    FiCalendar,
} from "react-icons/fi";

import { CACHE_RESPONSE_API, CACHE_RESPONSE_DATA } from '../../sw/utils/constants'
import { getDataFromStore } from '../../sw/utils/db'

// Initialize client and messages
const client = new FastAPIClient(config);

const messages = {
    en: messages_en,
    fr: messages_fr,
};

// Custom color set for Sendbird
const colorSet = {
    "--sendbird-light-primary-500": "#000000",
    "--sendbird-light-primary-400": "#000000",
    "--sendbird-light-primary-300": "#000000",
    "--sendbird-light-primary-200": "#000000",
    "--sendbird-light-primary-100": "#000000",
};

const formatModalDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    const hours = String(d.getHours()).padStart(2, "0");
    const minutes = String(d.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
};

const ClientPage = () => {
    const { clientId } = useParams();
    const [clientInfo, setClientInfo] = useState([]);
    const [product, setProductRecos] = useState([]);
    const [list, setList] = useState([]);
    const [meetinghistory, setClientMeetingHistory] = useState([]);
    const [clientorders, setClientOrders] = useState([]);
    const [clientreceipts, setClientReceipts] = useState([]);
    const [reportlayout, setReportLayout] = useState([]);
    const [clientstats, setClientStats] = useState([]);
    const targetDivRef = useRef(null);
    const [refreshing, setRefreshing] = useState(true);
    const [openmodal, setOpenModal] = useState(false);
    const [locale, setLocale] = useState();
    const [user, setUser] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [reminderModal, setReminderModal] = useState(false);
    const [message, setMessage] = useState(null); // State to store message
    const [messageType, setMessageType] = useState(null); // Type of message (success or error)

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const meetingId = queryParams.get("meeting_id"); // Extract meeting_id
    const meeting_type_id = queryParams.get("meeting_type_id"); // Extract meeting_id
    const { offlineMeetings, offlineRecommendations } = useOfflineMeetings();

    const now = new Date();
    const initialStartDate = formatModalDate(now); // Current date and time
    const initialEndDate = formatModalDate(
        new Date(now.getTime() + 30 * 60000)
    ); // Current date and time plus 30 minutes

    const [newEventData, setNewEventData] = useState({
        title: "",
        start: initialStartDate,
        end: initialEndDate,
    });
    
    // concat offlineMeetings with meetinghistory
    const totalMeetings = useMemo(() => {
        if(offlineMeetings.length && clientId) {
          // Filter offlineMeetings by clientId
          const currentClientsMeetings = offlineMeetings.filter(item => item.client_id === clientId)
          if(meetinghistory.length) {
            const mergeMeetings = meetinghistory.concat(currentClientsMeetings)
            return mergeMeetings.sort((a, b) => new Date(a.start_date) - new Date(b.start_date))
          }
          return currentClientsMeetings.sort((a, b) => new Date(a.start_date) - new Date(b.start_date))
        }
        return meetinghistory
      }, [offlineMeetings, meetinghistory, clientId])
    const openModal = () => {
        setNewEventData({
            ...newEventData,
            start: initialStartDate,
            end: initialEndDate,
        });
        setOpenModal(true); // This navigates back one step in the history
    };
    const closeModal = () => {
        setOpenModal(false); // This navigates back one step in the history
    };

    const [stringSet] = useState({
        MESSAGE_INPUT__PLACE_HOLDER: "Saisissez un message.", // You can display these texts in other languages.
        MESSAGE_INPUT__PLACE_HOLDER__DISABLED:
            "Le chat est indisponible dans ce canal.",
        MESSAGE_INPUT__PLACE_HOLDER__MUTED:
            "Le chat est indisponible parce que vous êtes sous le coup de mute.",
        CHANNEL__MESSAGE_LIST__NOTIFICATION__NEW_MESSAGE:
            "Nouveau(x) message(s) depuis.",
    });

    useEffect(() => {
        setLocale(localStorage.getItem("language"));
    }, []);

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1); // This navigates back one step in the history
    };

    const updateReminderModal = (value) => {
        setReminderModal(!value);
    };

    const handleStartVisit = () => {
        setToggle(true);
    };

    const handleSaveVisit = () => {
        setToggle(false);

        getClientInfo(clientId);
    };

    useEffect(() => {
        getUserName();
    }, []);

    useEffect(() => {
        // Scroll to the start of the target div when 'toggle' changes
        if (toggle && targetDivRef.current) {
            setTimeout(() => {
                targetDivRef.current.scrollIntoView({ behavior: "smooth" });
            }, 100); // Adjust the delay as needed a
        }
    }, [toggle, targetDivRef]);

    // Monitor whether the current user's recommendation information is updated offline
    const totalList = useMemo(() => {
      if(list.length) {
        if(offlineRecommendations.length) {
            return list.filter(item => 
            !offlineRecommendations.some(recommendation => recommendation.client_id === item.client_id && recommendation.task_id == item.task_id)
            );
        }
        return list
      } 
      return []
    }, [list, offlineRecommendations]);

    useEffect(() => {
      if(user.user_id && clientId) {
        getClientInfo(clientId);

        getReportLayout();

        targetDivRef.current = document.getElementById("yourTargetDivId");
      }
    }, [user, clientId]);

    const getUserName = () => {
        client.fetchUser().then((data) => {
            setUser(data);
        });
    };

    const getAllOfflineClients = () => {
        const storeName = CACHE_RESPONSE_DATA[CACHE_RESPONSE_API.CLIENTS].storeName
        const url = `${config.apiBasePath}${CACHE_RESPONSE_API.CLIENTS}?user_id=${user.user_id}`
        getDataFromStore(storeName, url)
        .then(result => {
          if (result?.data?.length) {
            // setAllOfflineClients(data)
            const currentClients = result?.data?.filter(item => item.client_id === clientId)
            console.log('currentClients', currentClients);
            handleClientDetailInfo(currentClients)
          } else {
            console.log('No data found for the given URL.');
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    };

    const getClientInfo = (id) => {
      getAllOfflineClients()
        // if (navigator.onLine) {
        //   client.getUserClients(null, id).then((data) => {
        //     console.log(data);
        //     if (data.length === 1) {
        //         console.log(data[0].client_name); // Access client_name directly
        //     }
        //     handleClientDetailInfo(data)
        // });
        // } else {
        //   getAllOfflineClients()
        // }


    };

    const handleClientDetailInfo = (data) => {
      setClientInfo(data[0]);
      setClientStats(data[0].statistics);
      setClientReceipts(data[0].receipts);
      setProductRecos(data[0].product_recommendations);
      setList(data[0].next_best_actions);
      setClientOrders(data[0].orders);
      setClientMeetingHistory(data[0].meetings);
      setRefreshing(false);
    }

    const getReportLayout = () => {
        client.getReportLayout().then((data) => {
            setReportLayout(data?.results);
        });
    };

    const handleSendMail = async () => {
        try {
            // Attempt to send email
            await client.sendMail({
                to: "tobi@myway.technology",
                subject: `Demande de dépannage pour ${clientInfo.client_name}`,
                content: `Hello ${clientInfo.client_name},\n\nThis is a message sent from your application.`,
                client_id: clientId,
                user_id: user.user_id,
            });

            // If email is sent successfully
            setMessage("Email envoyé avec succès!"); // Success message in French
            setMessageType("success");

            // Automatically hide the message after 2 seconds
            setTimeout(() => {
                setMessage(null);
            }, 2000);
        } catch (error) {
            // If there's an error sending the email
            setMessage("Une erreur s'est produite. Veuillez réessayer."); // Error message in French
            setMessageType("error");

            // Automatically hide the message after 2 seconds
            setTimeout(() => {
                setMessage(null);
            }, 2000);
        }
    };

    if (refreshing) {
        return <Loader />; // Display Loader while data is being fetched
    }

    return (
        <section className="flex flex-row bg-white">
            <div
                style={{
                    flex: 1,
                    overflowY: "auto",
                }}
            >
                <IntlProvider locale={locale} messages={messages[locale]}>
                    <BackButton handleGoBack={handleGoBack} />

                    <div className="flex flex-row items-center mt-5">
                        <div className="xl:ml-10 pl-4 text-left text-black bg-white text-xl font-bold uppercase text-black">
                            {clientInfo?.client_name}
                        </div>

                        <button
                            className=" ml-5 bg-white border border-buttonSecondaryBorder rounded-full text-buttonSecondaryFontColor pl-2 pr-2 text-sm hover:bg-buttonSecondaryHoverBackground hover:text-buttonSecondaryHoverFontColor"
                            onClick={() => updateReminderModal(reminderModal)}
                        >
                            {" "}
                            + Planifier rapell
                        </button>
                        {reminderModal && (
                            <ReminderModal closeModal={updateReminderModal} />
                        )}
                    </div>
                    <div className="xl:ml-10 pl-4 text-left text-gray-400 bg-white text-base font-bold uppercase text-black">
                        ID: {clientInfo?.client_id}
                    </div>
                    <div className="flex flex-col md:flex-row w-full xl:pr-10 xl:pl-10 mt-4">
                        <div className="float-left md:w-1/4 flex-col">
                            <CollapseComponent
                                text={
                                    <FormattedMessage
                                        id="client.client_details"
                                        values={{ number: 1 }}
                                    />
                                }
                                icon={<FiBookOpen />}
                                maximize={false}
                                open={true}
                            >
                                {clientInfo && (
                                    <CustomerDetails customer={clientInfo} />
                                )}
                            </CollapseComponent>
                            <CollapseComponent
                                text={
                                    <FormattedMessage
                                        id="client.sales"
                                        values={{ number: 1 }}
                                    />
                                }
                                icon={<FiBarChart2 />}
                                maximize={true}
                                open={true}
                                statistics={clientstats}
                            >
                                <ClientStatistics statistics={clientstats} />
                            </CollapseComponent>
                        </div>

                        <div className="flex flex-col float-left md:w-1/2">
                            <CollapseComponent
                                text={
                                    <FormattedMessage
                                        id="client.nba"
                                        values={{ number: 1 }}
                                    />
                                }
                                icon={<FiUsers />}
                                maximize={false}
                                open={true}
                            >
                                <ul className="display-inline text-gray-700 mb-5 text-sm w-5/6">
                                    {totalList.filter((item) => !item.read)
                                        .length === 0 ? (
                                        <h1>
                                            <FormattedMessage id="client.notasks" />
                                        </h1>
                                    ) : (
                                        totalList
                                            .filter((item) => !item.read) // Only include items where read is False
                                            .map((item) => (
                                                <List
                                                    recommendation={item}
                                                    key={item.task_id}
                                                    getRecommendations={
                                                        getClientInfo
                                                    }
                                                    clientInfo={clientInfo}
                                                    client_id={clientId}
                                                />
                                            ))
                                    )}
                                </ul>
                            </CollapseComponent>
                            <CollapseComponent
                                text={
                                    <FormattedMessage
                                        id="client.products"
                                        values={{ number: 1 }}
                                    />
                                }
                                icon={<FiShoppingCart />}
                                maximize={false}
                                toggle={true}
                                open={true}
                            >
                                <ClientOrders clientorders={clientorders} />
                            </CollapseComponent>
                            {!user.storemodule && (
                                <CollapseComponent
                                    text={
                                        <FormattedMessage
                                            id="client.client_history"
                                            values={{ number: 1 }}
                                        />
                                    }
                                    icon={<FiFileText />}
                                    maximize={false}
                                    toggle={true}
                                    open={false}
                                >
                                    <OrderHistory
                                        orderhistory={clientreceipts}
                                    />
                                </CollapseComponent>
                            )}
                            {!user.storemodule && (
                                <CollapseComponent
                                    text={
                                        <FormattedMessage
                                            id="client.client_interactions"
                                            values={{ number: 1 }}
                                        />
                                    }
                                    icon={<FiUsers />}
                                    maximize={false}
                                    toggle={true}
                                    open={false}
                                >
                                    <VisitHistory
                                        visithistory={totalMeetings}
                                    />
                                </CollapseComponent>
                            )}
                        </div>

                        <div className="flex flex-col overflow-hidden touch-none float-left md:w-1/4">
                            <CollapseComponent
                                text={
                                    <FormattedMessage
                                        id="client.product_reco"
                                        values={{ number: 1 }}
                                    />
                                }
                                icon={<FiShuffle />}
                                maximize={false}
                                open={true}
                            >
                                {product.length &&
                                    product.map((prod) => (
                                        <Product
                                            product={prod}
                                            key={prod.product_id}
                                        />
                                    ))}{" "}
                            </CollapseComponent>

                            <div className="rounded-md ml-3">
                                <Sendbird
                                    appId="FC256172-7B00-4B52-879E-051BADF94246"
                                    userId={"tobi"}
                                    nickname={"Tobias Holler"}
                                    colorSet={colorSet}
                                    config={{
                                        userMention: {
                                            maxMentionCount: 10,
                                            maxSuggestionCount: 15,
                                        },
                                    }}
                                    isMentionEnabled
                                    stringSet={stringSet}
                                >
                                    <CustomizedApp
                                        channelUrl={clientInfo?.sendbird_url}
                                    />
                                </Sendbird>
                            </div>
                        </div>
                    </div>
                    <div className=""></div>

                    {message && (
                        <div
                            className={`fixed bottom-10 left-1/2 transform -translate-x-1/2 p-4 rounded-md text-white ${
                                messageType === "success"
                                    ? "bg-green-500"
                                    : "bg-red-500"
                            }`}
                            style={{ zIndex: 999 }}
                        >
                            {message}
                        </div>
                    )}

                    {!toggle && (
                        <div className="absolute bottom-0 right-10 flex-col items-center">
                            <div className="flex flex-row w-full mt-5 mb-5 md:mb-5">
                                <div className="w-2/3 md:w-5/6"></div>

                                <button
                                    onClick={() => handleStartVisit()}
                                    className="bg-gold rounded-full text-lg text-black rounded-full font-semibold p-5 drop-shadow-lg "
                                >
                                    <FiEdit />
                                </button>

                                <button
                                    onClick={openModal}
                                    className="bg-black rounded-full text-lg text-gold font-semibold p-5 drop-shadow-lg ml-10 "
                                >
                                    <FiCalendar />
                                </button>
                                <button
                                    onClick={() =>
                                        window.open(
                                            "https://client.lebihanboissons.com/",
                                            "_blank"
                                        )
                                    }
                                    className="bg-black rounded-full text-lg text-gold font-semibold p-5 drop-shadow-lg ml-10 "
                                >
                                    <FiShoppingBag />
                                </button>
                                <button
                                    onClick={handleSendMail}
                                    className="bg-black rounded-full text-lg text-gold font-semibold p-5 drop-shadow-lg ml-10"
                                >
                                    <FiAlertTriangle />
                                </button>
                            </div>
                        </div>
                    )}
                    <div id="yourTargetDivId"></div>
                    {toggle && (
                        <section className="w-full bg-white flex flex-col items-center border-t border-gray-100  mt-5">
                            <ReportForm
                                reportlayout={reportlayout}
                                setToggle={handleSaveVisit}
                                clientId={clientId}
                                meetingId={meetingId}
                                userId={user.user_id}
                                userName={user.first_name}
                                meeting_type_id={meeting_type_id}
                            />
                        </section>
                    )}
                    {openmodal && (
                        <section className="w-full bg-white flex flex-col items-center border-t border-gray-100  mt-5">
                            <CreateMeetingModal
                                closeModal={closeModal}
                                locale={locale}
                                user_id={user.user_id}
                                initialEventData={newEventData}
                                isEditing={false}
                                user={user}
                            />
                        </section>
                    )}
                </IntlProvider>
            </div>
        </section>
    );
};

export default ClientPage;
