/*eslint-disable*/

import React, { useRef } from "react";

import { Link } from "react-router-dom";

import { FormattedMessage, IntlProvider } from "react-intl";

import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";

const messages = {
    en: messages_en,
    pt: messages_pt,
    fr: messages_fr,
};

const locale = "fr";

const ClientMapCard = ({ id, text, time, isLastCard, alert, locale }) => {
    const ref = useRef(null);

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            <div
                ref={ref}
                style={{
                    borderColor: "#f3f4f6",

                    backgroundColor: "white",
                }}
                className="w-full"
            >
                <div className="flex flex-row p-2">
                    <div
                        className={`w-full ${
                            isLastCard ? "" : "border-b border-gray-200"
                        }`}
                    >
                        <div className="flex flex-row items-center p-2 mb-2">
                            <div className="flex flex-col w-2/3 text-left">
                                <div className="flex flex-row">
                                    <div className="mb-2">{text} </div>
                                    {alert && (
                                        <div className="flex ml-2 flex-row items-center bg-gold rounded-full text-black text-xxxs font-bold text-center mb-2 pl-1 pr-1 whitespace-nowrap flex-shrink-0 h-[25px]">
                                            <div className="">
                                                {" "}
                                                <FormattedMessage
                                                    id="routing.new_reco"
                                                    values={{ number: 1 }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="w-2/3 text-gray-400 text-sm text-left mb-2">
                                    {time.start} - {time.end}
                                </div>
                            </div>
                            <div className="flex flex-col w-1/3 left">
                                <div className="flex flex-row w-full">
                                    <div className="w-5/6"></div>
                                    <div className="w-1/6 "></div>
                                </div>
                                <div className="left mb-4"> </div>
                                <div className="left mt-5">
                                    {" "}
                                    <Link
                                        to={`/client/${id}`}
                                        key={id}
                                        className="text-xs text-white border border-black font-semibold bg-black cursor-pointer hover:bg-buttonMainHoverBackground hover:text-buttonMainHoverFontColor px-3 py-2 mx-auto mt-3 rounded-full"
                                    >
                                        <FormattedMessage
                                            id="routing.start_visit"
                                            values={{ number: 1 }}
                                        />
                                    </Link>
                                </div>
                            </div>{" "}
                        </div>
                    </div>
                </div>
            </div>
        </IntlProvider>
    );
};

export default ClientMapCard;
