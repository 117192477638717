import { openDB } from 'idb';
import { CACHE_RESPONSE_API,OFFINE_OPERATE_API } from './constants'
import { getValuesList, getDBResponsenName, getDBOperateName } from './index'

// Open the api-cache database
// Api-cache is the name of the database where the api response data is stored
const responseDateDbPromise = openDB('api-cache', 1, {
  upgrade(db) {
    getValuesList(CACHE_RESPONSE_API).forEach(apiPath => {
      const storeName = getDBResponsenName(apiPath); // Get store name
      if (storeName && !db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName, { keyPath: 'url' });
      }
    });
  },
});

// Open the operate-data database
// operate-data is the name of the database where offline operational data is stored (add, edit, delete).
const operateDataDbPromise = openDB('operate-data', 1, {
  upgrade(db) {
     // Helper function to create object store if it does not exist
     function createObjectStoreIfNotExists(storeConfig) {
      if (!db.objectStoreNames.contains(storeConfig.storeName)) {
        db.createObjectStore(storeConfig.storeName, { keyPath: storeConfig.keyPath, autoIncrement: storeConfig.autoIncrement });
      }
    }
    // Iterate over OFFINE_OPERATE_API and create necessary object stores
    getValuesList(OFFINE_OPERATE_API).forEach(api => {
      const storeDataItem = getDBOperateName(api);
      if (storeDataItem) {
        createObjectStoreIfNotExists({
          storeName: storeDataItem.storeName,
          keyPath: storeDataItem.keyPath,
          autoIncrement: storeDataItem.keyPath === 'id'
        });
      }
    });
  },
  
});


// Delete offline data from indexDB
async function onDeleteOfflineData(storeName, id, event) {
  try {
    await operateDataDbPromise.then(async (db) => {
      const tx = db.transaction(storeName, 'readwrite');
      const store = tx.objectStore(storeName);
      await store.delete(id);
    });
    // Notifies the client that data in indexDB is successfully deleted
    if(event.source && event.source.postMessage) {
      event.source.postMessage({
        type: 'DELETE_OFFLINE_MEETING_SUCCESS',
        payload: { key_path_id: id }
      });
    }
  } catch (error) {
    // Deleting data from indexDB failed. Procedure
    console.error('Error during delete operation:', error);
  }
}


// Get cached data from IndexedDB
async function getCachedResponse(url, storeName) {
  const urlStr = url.toString();
  const cachedData = await responseDateDbPromise.then(db => db.get(storeName, urlStr));
  if (cachedData) {
    // Return cached data
    return new Response(JSON.stringify(cachedData.data), { status: 200 });
  }
  return null;
}

async function getDataFromStore(storeName, key) {
  const db = await responseDateDbPromise;
  const tx = db.transaction(storeName, 'readonly');
  const store = tx.objectStore(storeName);

  const data = await store.get(key);
  await tx.done;

  return data;
}



export { 
  getCachedResponse, 
  onDeleteOfflineData,
  getDataFromStore, 
  responseDateDbPromise, 
  operateDataDbPromise,
}