/*eslint-disable*/

import React, { useState } from "react";

const colorSet = {
    "--sendbird-light-primary-500": "#000000",
    "--sendbird-light-primary-400": "#000000",
    "--sendbird-light-primary-300": "#000000",
    "--sendbird-light-primary-200": "#000000",
    "--sendbird-light-primary-100": "#000000",
};

import {
    SendBirdProvider as Sendbird,
    OpenChannel,
    OpenChannelSettings,
} from "@sendbird/uikit-react";
import "@sendbird/uikit-react/dist/index.css";

import SendbirdChat from "../../components/Sendbird/SendbirdChat";

const Chat = () => {
    const [stringSet] = useState({
        MESSAGE_INPUT__PLACE_HOLDER: "Saisissez un message.", // You can display these texts in other languages.
        MESSAGE_INPUT__PLACE_HOLDER__DISABLED:
            "Le chat est indisponible dans ce canal.",
        MESSAGE_INPUT__PLACE_HOLDER__MUTED:
            "Le chat est indisponible parce que vous êtes sous le coup de mute.",
        CHANNEL__MESSAGE_LIST__NOTIFICATION__NEW_MESSAGE:
            "Nouveau(x) message(s) depuis.",
    });
    return (
        <>
            <section className="min-h-screen max-h-screen">
                <div className="rounded-md ml-3 h-full max-h-screen">
                    <Sendbird
                        appId="FC256172-7B00-4B52-879E-051BADF94246"
                        userId={"tobi"}
                        nickname={"Tobias Holler"}
                        colorSet={colorSet}
                        config={{
                            userMention: {
                                maxMentionCount: 10,
                                maxSuggestionCount: 15,
                            },
                        }}
                        isMentionEnabled
                        stringSet={stringSet}
                    >
                        <SendbirdChat
                            channelUrl={
                                "sendbird_group_channel_440817856_d20196689dec960548807f61631837b258683435"
                            }
                            channellist={true}
                        />
                    </Sendbird>
                </div>
            </section>
        </>
    );
};

export default Chat;
