import React, { useState, useEffect } from "react";
// import DashboardHeader from "../../components/DashboardHeader";
// import Footer from "../../../components/Footer";
import PopupModal from "../../../components/ModalComponents/Modal/PopupModal";
// import Navigator from "../../../components/Navigator";
// import useAuthentication from "../../../components/Authenticate";
// import Loader from "../../../components/Loader";
// import { Navigate } from "react-router-dom";
// import MultiStep from "react-multistep";
import MultiStep from "../../../components/LayoutComponents/MultiStep";
import { StepOne } from "./step-one";
// import { StepTwo } from "./step-two";
import { StepThree } from "./step-three";
import { StepFour } from "./step-four";

import "./prog-track.css";

import { FormattedMessage, IntlProvider } from "react-intl";

import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";

const messages = {
    en: messages_en,
    pt: messages_pt,
    fr: messages_fr,
};

/*eslint-disable*/

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: "top",
        },
        title: {
            display: false,
            text: "Chart.js Bar Chart",
        },
    },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data1 = {
    labels,
    datasets: [
        {
            label: "Dataset 1",
            data: [2, 4, 6, 8, 8, 11, 12],
            borderColor: "rgb(71 85 105)",
            backgroundColor: "rgb(71 85 105)",
        },
    ],
};

export const data2 = {
    labels,
    datasets: [
        {
            label: "Dataset 1",
            data: [2, 4, 2, 6, 8, 8, 7],
            backgroundColor: "rgb(8, 145, 178)",
        },
    ],
};

const customers = [
    {
        id: "0",
        rank: "1",
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/1200px-Circle-icons-profile.svg.png",
        name: "Chen",
        location: "🇮🇱",
        spent: "2,890",
    },
    {
        id: "1",
        rank: "2",
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/1200px-Circle-icons-profile.svg.png",
        name: "Chloe",
        location: "🇫🇷",
        spent: "2,767",
    },
    {
        id: "2",
        rank: "3",
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/1200px-Circle-icons-profile.svg.png",
        name: "James",
        location: "🇬🇧",
        spent: "2,996",
    },
];

const training =
    "container flex flex-row bg-white rounded-md drop-shadow mt-5 w-full xl:w-5/6 md:w-5/6";

const steps = [
    { name: "Name A", component: <StepOne /> },
    // { name: "Email", component: <StepTwo /> },
    { name: "Password", component: <StepThree /> },
    { name: "Agreement", component: <StepFour /> },
];

import FastAPIClient from "../../../client";
import config from "../../../config";

const client = new FastAPIClient(config);

const Training = () => {
    // if (refreshing) return !isLoggedIn ? <NotLoggedIn /> : <Loader />;

    const [username, setUsername] = useState(false);

    // const { isLoggedIn } = useAuthentication();
    // const [refreshing, setRefreshing] = useState(true);
    const [locale, setLocale] = useState();

    useEffect(() => {
        getUserName();
        // setRefreshing(false);
        setLocale(localStorage.getItem("language"));
    }, []);

    const getUserName = () => {
        client.fetchUser().then((data) => {
            setUsername(data);
        });
    };

    const [show, setShow] = useState(false);

    const buttonStyle =
        "transition ease-in-out rounded-full  delay-150 hover:-translate-y-0 hover:scale-100 bg-buttonSecondaryBackground border-2 border-buttonSecondaryBorder text-buttonSecondaryFontColor cursor-pointer hover:bg-buttonSecondaryHoverBackground hover:text-buttonSecondaryHoverFontColor font-bold text-xs px-4 py-2 mx-auto mt-3";

    // if (refreshing) {
    //     return <Loader />; // Display Loader while data is being fetched
    // }

    return (
        <>
            <IntlProvider locale={locale} messages={messages[locale]}>
                <section
                    className="flex flex-col text-center bg-white w-3/4 ml-10 bg-white"
                    style={{ minHeight: "100vh" }}
                >
                    <div className=" container flex flex-row items-center pt-10 mr-20 w-full mt-10">
                        {/* <div className="flex flex-col justify-center v-screen ml-20">
                            <embed
                                className=""
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/1200px-Circle-icons-profile.svg.png"
                                alt="triangle with all three sides equal"
                                height="110px"
                                width="110px"
                            />
                        </div> */}
                        <div className=" text-left">
                            <div className="flex flex-row items-center">
                                <h1 className="text-xl text-slate-900">
                                    {username.first_name}
                                </h1>
                                <h1 className="ml-2 text-xs text-gray-500 mt-2">
                                    (
                                    <FormattedMessage
                                        id="training.level"
                                        values={{ number: 1 }}
                                    />{" "}
                                    10)
                                </h1>
                                <div className="flex flex-row items-center justify-center v-screen">
                                    <embed
                                        className=""
                                        src="https://www.iconpacks.net/icons/1/free-badge-icon-1361-thumb.png"
                                        alt="triangle with all three sides equal"
                                        height="25px"
                                        width="25px"
                                    />
                                    <embed
                                        className=""
                                        src="https://www.iconpacks.net/icons/1/free-badge-icon-1361-thumb.png"
                                        alt="triangle with all three sides equal"
                                        height="25px"
                                        width="25px"
                                    />
                                    <embed
                                        className=""
                                        src="https://www.iconpacks.net/icons/1/free-badge-icon-1361-thumb.png"
                                        alt="triangle with all three sides equal"
                                        height="25px"
                                        width="25px"
                                    />
                                </div>
                            </div>
                            <div>
                                <div className=" flex flex-col items-center text-base text-slate-600">
                                    <div className="text-xs text-slate-600 mt-3 mb-5">
                                        <FormattedMessage
                                            id="training.progress"
                                            values={{ number: 1 }}
                                        />
                                    </div>
                                    <div className="relative h-1 mb-2 bg-gray-200 rounded pl-20 pr-20">
                                        <div className="absolute top-0 left-0 w-4/6 h-full bg-cyan-500 rounded"></div>
                                    </div>
                                    <div className="text-xs text-slate-600 mt-1">
                                        400/600 points
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" flex flex-row pb-6 text-black w-full ">
                        <div className="container flex flex-col mt-5 w-full xl:w-2/3 md:w-2/3">
                            <div
                                className={training}
                                style={{ maxHeight: 150 }}
                            >
                                <div
                                    className="border bg-gold border-gold rounded-tl-lg rounded-br-lg absolute z-50"
                                    style={{
                                        left: 0,
                                        top: 0,
                                        minWidth: 60,
                                        minHeight: 20,
                                    }}
                                >
                                    <span className="text-white font-semibold text-xs">
                                        <FormattedMessage id="training.new" />
                                    </span>
                                </div>
                                <div className="grayscale w-full xl:w-1/4 md:w-1/4">
                                    <img
                                        style={{
                                            width: 200,
                                            height: 200,
                                        }}
                                        className="max-h-full max-w-fit rounded-l-lg"
                                        // src="https://i0.wp.com/www.antoinemoueix.com/wp-content/uploads/2019/10/vignette-diapo-accueil-chateau-capet-guillier.jpg?w=800&ssl=1"
                                        // src="https://www.videoinabox.co.uk/content/uploaded/Case%20Studies/Royal%20Canin/Royal-Canin-Wet-Cat-Food-Video-Box-CS-3-900x800px.jpg"
                                        src="https://www.c10.fr/assets/uploads/2023/07/VIGNETTES-320X280px.jpg"
                                    />
                                </div>
                                {/* <div className="grayscale w-full xl:w-1/4 md:w-1/4">
                                <img style={{
                                    width: 200,
                                    height: 150
                                }} className="max-h-full max-w-fit rounded-l-lg ml-10" src="https://www.heineken.com/media-eu/joqbtvt2/beertender.png?anchor=center&mode=crop&width=432&height=648&quality=85" />
                            </div> */}

                                <div className="mt-5 ml-20 text-left">
                                    {/* <h1 className="font-semibold text-slate-900">
                                        Antoine Moueix
                                    </h1> */}
                                    {/* <h1 className="font-semibold text-slate-900">
                                        Introducing Royal Canin Sensory
                                    </h1> */}
                                    <h1 className="font-semibold text-slate-900">
                                        <FormattedMessage id="training_1.title" />
                                    </h1>
                                    {/* <h1 className="font-semibold text-slate-900">
                                            Découvrir les produits Bèl Arrangé
                                        </h1> */}

                                    {/* <h1 className="text-xs leading-5 text-gray-700">
                                        Antoine Moueix Properties now reaches
                                        out across the entire Bordeaux region
                                        with almost 300 hectares of vineyards on
                                        the right and left banks.
                                    </h1> */}
                                    {/* <h1 className="text-xs leading-5 text-gray-700">
                                        Learn about the Royal Canin video boxes
                                        we created to promote our new range of
                                        cat food, sensory cat food to be exact.
                                        A small box that can fit a few pouches
                                        from the new range, so you can show the
                                        consumer why our range is for them.
                                    </h1> */}
                                    <h1 className="text-xs leading-5 text-gray-700">
                                        <FormattedMessage id="training_1.text" />
                                    </h1>
                                </div>
                                {/* <div className="mt-5 ml-20 text-left">
                                <h1 className="font-semibold text-slate-900">The BeerTender®</h1>
                                <h1 className="text-xs leading-5 text-gray-700">Serving the perfect draft beer isnt just for professionals. With BeerTender®, take advantage of a high-capacity, professional-quality beer dispenser..</h1>
                            </div> */}
                                <div className="pt-20 ml-5 mr-2">
                                    <a
                                        className={buttonStyle}
                                        onClick={setShow}
                                    >
                                        <FormattedMessage
                                            id="training.launch"
                                            values={{ number: 1 }}
                                        />
                                    </a>
                                    <h1 className="text-xs mt-2">+10pts</h1>
                                </div>
                            </div>
                            <div
                                className={training}
                                style={{ maxHeight: 150 }}
                            >
                                <div className="grayscale w-full xl:w-1/4 md:w-1/4">
                                    <img
                                        className="max-h-full max-w-fit rounded-l-lg"
                                        style={{
                                            width: 200,
                                            height: 150,
                                        }}
                                        src="https://aprioboardportal.com/wp-content/uploads/2020/02/blog-board-meeting-prep-checklist.jpg"
                                    />
                                </div>
                                <div className="mt-5 ml-20 text-left">
                                    {/* <h1 className="font-semibold text-slate-900">
                                        Visit Preparation
                                    </h1>
                                    <h1 className="text-xs leading-5 text-gray-700">
                                        Learn about the key factors relevant to
                                        prepare your visit adequatley and
                                        increase conversion rates.
                                    </h1> */}
                                    <h1 className="font-semibold text-slate-900">
                                        <FormattedMessage id="training_2.title" />
                                    </h1>
                                    <h1 className="text-xs leading-5 text-gray-700">
                                        <FormattedMessage id="training_2.text" />
                                    </h1>
                                </div>
                                <div className="pt-20 ml-1 mr-2">
                                    <a className={buttonStyle}>
                                        {" "}
                                        <FormattedMessage
                                            id="training.launch"
                                            values={{ number: 1 }}
                                        />
                                    </a>
                                    <h1 className="text-xs mt-2">+10pts</h1>
                                </div>
                            </div>
                            <div
                                className={training}
                                style={{ maxHeight: 150 }}
                            >
                                <div className="grayscale w-full xl:w-1/4 md:w-1/4">
                                    <img
                                        style={{
                                            width: 200,
                                            height: 150,
                                        }}
                                        className="max-h-full max-w-fit rounded-l-lg"
                                        src="https://53.fs1.hubspotusercontent-na1.net/hub/53/hubfs/dealing%20with%20difficult%20customers.jpg?width=1190&height=800&name=dealing%20with%20difficult%20customers.jpg"
                                    />
                                </div>
                                <div className="mt-5 ml-20 text-left text-md">
                                    {/* <h1 className="font-semibold text-slate-900">
                                        How to engange difficult clients
                                    </h1>
                                    <h1 className="text-xs leading-5 text-gray-700">
                                        Learn opening and engaging best
                                        practives. Understand what is important
                                        to build rapport and build a long
                                        lasting client relationship.
                                    </h1> */}
                                    <h1 className="font-semibold text-slate-900">
                                        <FormattedMessage id="training_3.title" />
                                    </h1>
                                    <h1 className="text-xs leading-5 text-gray-700">
                                        <FormattedMessage id="training_3.text" />
                                    </h1>
                                </div>
                                <div className="pt-20 ml-5 mr-2">
                                    <a className={buttonStyle}>
                                        <FormattedMessage
                                            id="training.launch"
                                            values={{ number: 1 }}
                                        />
                                    </a>
                                    <h1 className="text-xs mt-2">+10pts</h1>
                                </div>
                            </div>
                        </div>
                        {show && (
                            <PopupModal
                                onCloseBtnPress={() => {
                                    setShow(false);
                                }}
                            >
                                <MultiStep
                                    showNavigation={true}
                                    steps={steps}
                                />
                            </PopupModal>
                        )}
                        <div className="flex flex-col item-center w-full xl:w-1/3 md:w-1/3">
                            <div className="bg-white rounded-md drop-shadow text-left mt-10">
                                <div className="mt-5 mb-5 ml-10 font-bold">
                                    <FormattedMessage
                                        id="training.best_practice_title"
                                        values={{ number: 1 }}
                                    />
                                </div>
                                <div className="flex ml-10">
                                    <div className="w-15 h-15 shrink-0 mr-2 sm:mr-3">
                                        <img
                                            className="rounded-md"
                                            src="https://t4.ftcdn.net/jpg/03/83/25/83/360_F_383258331_D8imaEMl8Q3lf7EKU2Pi78Cn0R7KkW9o.jpg"
                                            width="50"
                                            height="50"
                                            alt="bla"
                                        />
                                    </div>
                                    <div className="text-slate-800 text-sm font-bold mt-2 text-gray-700">
                                        Chloe
                                    </div>
                                </div>
                                <div className="mb-5 ml-10 mt-5 mr-5 text-sm">
                                    <FormattedMessage
                                        id="training.best_practice"
                                        values={{ number: 1 }}
                                    />
                                </div>
                                <div className="flex ml-40 align-right w-2/3">
                                    <div className="mb-5 ml-10 text-sm">
                                        <img
                                            src="https://cdns.iconmonstr.com/wp-content/releases/preview/2018/240/iconmonstr-speech-bubble-comments-thin.png"
                                            height="20"
                                            width="20"
                                        />
                                    </div>
                                    <div className="mb-5 ml-10 text-sm">
                                        <img
                                            src="https://www.svgrepo.com/show/344748/emoji-heart-eyes.svg"
                                            height="20"
                                            width="20"
                                        />
                                    </div>
                                    <div className="mb-5 ml-10 text-sm"></div>
                                </div>
                            </div>
                            <div className="bg-white rounded-md drop-shadow text-left mt-5">
                                <div className="mt-5 mb-5 ml-10 font-bold">
                                    <FormattedMessage
                                        id="training.leadership_title"
                                        values={{ number: 1 }}
                                    />
                                </div>

                                <table className="table-auto w-5/6 ml-10">
                                    <thead className="text-xs font-semibold uppercase text-slate-600 bg-slate-50">
                                        <tr>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-left">
                                                    <FormattedMessage
                                                        id="training.rank"
                                                        values={{
                                                            number: 1,
                                                        }}
                                                    />
                                                </div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-left">
                                                    <FormattedMessage
                                                        id="training.name"
                                                        values={{
                                                            number: 1,
                                                        }}
                                                    />
                                                </div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-left">
                                                    Points
                                                </div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">
                                                    <FormattedMessage
                                                        id="training.country"
                                                        values={{
                                                            number: 1,
                                                        }}
                                                    />
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-xs divide-y divide-slate-100">
                                        {customers.map((customer) => {
                                            return (
                                                <tr key={customer.id}>
                                                    <td className="p-2 whitespace-nowrap">
                                                        <div className="text-left font-bold">
                                                            {customer.rank}
                                                        </div>
                                                    </td>
                                                    <td className="p-2 whitespace-nowrap">
                                                        <div className="flex items-center">
                                                            <div className="w-7 h-7 shrink-0 mr-2 sm:mr-3">
                                                                <img
                                                                    className="rounded-full"
                                                                    src={
                                                                        customer.image
                                                                    }
                                                                    width="25"
                                                                    height="25"
                                                                    alt={
                                                                        customer.name
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="font-medium text-slate-800">
                                                                {customer.name}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="p-2 whitespace-nowrap">
                                                        <div className="text-left font-medium">
                                                            {customer.spent}
                                                        </div>
                                                    </td>
                                                    <td className="p-2 whitespace-nowrap">
                                                        <div className="text-lg text-center">
                                                            {customer.location}
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </IntlProvider>
        </>
    );
};

export default Training;
