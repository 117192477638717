/*eslint-disable*/
import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Navigator from "../../components/Navigator";
import useAuthentication from "../../components/Authenticate";
import Loader from "../../components/Loader";
import { Navigate } from "react-router-dom";
import Header from "../../components/Header";
import { FiShoppingCart } from "react-icons/fi";
import { RxCheckCircled, RxCrossCircled } from "react-icons/rx";
import * as moment from "moment";
import jwtDecode from "jwt-decode";
import { useAuth0 } from "@auth0/auth0-react";

/*eslint-disable*/

// import DashboardHeader from "../../components/DashboardHeader";

import FastAPIClient from "../../client";

import config from "../../config";

/* eslint-disable */

const client = new FastAPIClient(config);

const Auth0 = () => {
    return <section className="flex flex-row bg-white w-full h-full"></section>;
};

export default Auth0;
