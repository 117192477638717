import React from "react";
import { FiArrowUp, FiArrowDown } from "react-icons/fi"; // Make sure to install react-icons

const ClientStatistics = ({ statistics }) => {
    return (
        <div className="flex flex-col w-5/6 justify-center">
            {statistics
                .filter((stat) => stat.display_main === true) // Filter out only display_main stats
                .sort((a, b) => a.data.name.localeCompare(b.data.name)) // Sort by stat.data.name in ascending order
                .map((stat, index) => (
                    <div key={index} className="mb-2">
                        {stat.statistic_type === "comparison" && (
                            <div className="w-full flex flex-row justify-between items-center">
                                <div className="text-left text-xs font-semibold w-2/3">
                                    {stat.data.name}
                                </div>
                                <div
                                    className={`rounded-full ${
                                        stat.data.change > 0
                                            ? "bg-gold text-black"
                                            : stat.data.change < 0
                                            ? "bg-rose-500 text-white"
                                            : "bg-gold text-black"
                                    } text-xxs font-bold w-12 h-5 flex items-center justify-center`}
                                >
                                    {parseFloat(stat.data.value).toFixed(0)}
                                </div>
                                <div
                                    className={`flex items-center ${
                                        stat.data.change > 0
                                            ? "text-gold"
                                            : "text-rose-500"
                                    } text-xs font-semibold w-1/5 ml-2`}
                                >
                                    {stat.data.change > 0 ? (
                                        <>
                                            <span className="ml-1">
                                                {parseFloat(
                                                    stat.data.change
                                                ).toFixed(0)}
                                                %
                                            </span>
                                            <FiArrowUp />
                                        </>
                                    ) : stat.data.change < 0 ? (
                                        <>
                                            <span className="ml-1">
                                                {parseFloat(
                                                    stat.data.change
                                                ).toFixed(0)}
                                                %
                                            </span>
                                            <FiArrowDown />
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        )}
                        {stat.statistic_type === "indicator" && (
                            <div className="w-full flex flex-row justify-between items-center">
                                <div className="text-left text-sm font-semibold w-2/3">
                                    {stat.data.name}
                                </div>
                                <div
                                    className={`rounded-full ${
                                        stat.data.value === "Faible"
                                            ? "bg-gold"
                                            : "bg-rose-500"
                                    } text-xxs font-bold w-12 h-5 flex items-center justify-center`}
                                >
                                    {stat.data.value}
                                </div>
                                <div
                                    className={`flex items-center ${
                                        stat.data.change > 0
                                            ? "text-gold"
                                            : "text-rose-500"
                                    } text-xs font-semibold w-1/5 ml-2`}
                                ></div>
                            </div>
                        )}
                    </div>
                ))}
        </div>
    );
};

export default ClientStatistics;
