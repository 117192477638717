import React from "react";

const SmallStatisticsBox = ({
    name,
    value_current_year,
    change,
    value_previous_year,
}) => {
    return (
        <div className="p-4 mb-3 bg-white rounded-md border border-gray-200 w-full mt-3 text-left">
            <h3 className="text-black text-lg">{name}</h3>
            <div className="flex items-center mt-3 mb-3">
                <span className="md:text-xl font-bold" data-config-id="value2">
                    {value_current_year}
                </span>
                <span
                    className={`inline-block ml-2 py-1 px-2  text-xs rounded-full ${
                        change.startsWith("-")
                            ? "bg-rose-500 text-white"
                            : "bg-gold text-black"
                    }`}
                    data-config-id="change2"
                >
                    {change}
                </span>
            </div>
            <div>
                <span
                    className="text-sm mt-2 text-black"
                    data-config-id="value2"
                >
                    {value_previous_year} l&apos;année dernière
                </span>
            </div>
        </div>
    );
};

export default SmallStatisticsBox;
