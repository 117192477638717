import React from "react";

// Reusable WidgetButton component
const SmallWidgetButton = ({ icon, text, notification, onClick }) => {
    const widgetStyle = `relative flex flex-col w-full h-full items-start justify-center bg-white text-black border border-gray-200 rounded-md p-5 hover:bg-gray-100`;
    const alertStyle = `text-black text-xxs font-bold mt-1 rounded-full bg-gold pl-2 pr-2 pt-1 pb-1`;
    const iconCircleStyle = `absolute top-0 left-0 mt-2 ml-2 w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center`;
    const spacingStyle = `mt-20 text-lg`; // Space between icon and text (left-aligned)

    return (
        <button className={widgetStyle} onClick={onClick}>
            <div className={iconCircleStyle}>{icon}</div>
            <div className={spacingStyle}>{text}</div>
            {notification && <div className={alertStyle}>{notification}</div>}
        </button>
    );
};

export default SmallWidgetButton;
