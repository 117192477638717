/*eslint-disable*/
import React, { useState } from "react";
import {
    FiMaximize2,
    FiX,
    FiChevronUp,
    FiChevronDown,
    FiArrowUp,
    FiArrowDown,
    FiBarChart2,
} from "react-icons/fi";

import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import ClientStatisticsPopUp from "../../ModalComponents/ClientStatisticsPopUp";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const CollapseComponent = ({
    text,
    icon,
    children,
    maximize, // Option to maximize
    toggle, // Option to toggle the collapse
    statistics, // Pass statistics data as a prop
    open,
}) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false); // Track if popup is open
    const [isOpen, setIsOpen] = useState(open); // Track the toggle state

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen); // Toggle popup visibility
    };

    const toggleCollapse = () => {
        setIsOpen(!isOpen); // Toggle collapse visibility
    };

    const iconCircleStyle = `flex w-8 h-8 bg-gray-100 ml-2 mt-2 rounded-full items-center justify-center`;

    return (
        <div className="overflow-hidden touch-none w-full mb-3">
            <div className="ml-3 mr-3 md:mr-0 border border-gray-200 bg-white rounded-md">
                <div className="flex flex-row w-full pl-2 items-center mb-2 mt-2">
                    <div className={iconCircleStyle}>{icon}</div>
                    <div className="flex text-lg md:text-base xl:text-lg text-black text-left ml-2 w-4/6 bg-white mt-2">
                        {text}
                    </div>
                    <div className="flex w-full xl:w-1/6 md:w-1/6 sm:w-1/6 w-1/6 items-center mt-2">
                        {maximize ? (
                            // Maximize button centered
                            <div className="flex w-full justify-center">
                                <button onClick={togglePopup}>
                                    <FiMaximize2 />
                                </button>
                            </div>
                        ) : toggle ? (
                            // Chevron aligned to the right
                            <div className="flex w-full justify-end">
                                <button
                                    onClick={toggleCollapse}
                                    className="text-xl"
                                >
                                    {isOpen ? (
                                        <FiChevronUp />
                                    ) : (
                                        <FiChevronDown />
                                    )}
                                </button>
                            </div>
                        ) : null}
                    </div>
                </div>

                {isOpen && (
                    <div
                        className="ml-4 mr-4 mb-5 text-left flex flex-col items-center overflow-y-auto"
                        style={{ maxHeight: "200px" }}
                    >
                        {children}
                    </div>
                )}
            </div>

            {/* Popup overlay */}
            {isPopupOpen && (
                <ClientStatisticsPopUp
                    statistics={statistics}
                    togglePopup={togglePopup}
                />
            )}
        </div>
    );
};

export default CollapseComponent;
