/*eslint-disable*/
import React, { useEffect, useState } from "react";
import FastAPIClient from "../../client";
import config from "../../config";
import ProductDetails from "../../components/LayoutComponents/ProductDetails";
import Loader from "../../components/Loader";
import "./style.css";
import ScrollFilterButton from "../../components/Buttons/ScrollFilterButton";

const client = new FastAPIClient(config);

import { FormattedMessage, IntlProvider } from "react-intl";

import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";

const messages = {
    en: messages_en,
    pt: messages_pt,
    fr: messages_fr,
};

const Products = () => {
    const [products, setProducts] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [selectedMaison, setSelectedMaison] = useState([]);
    const [selectedColour, setSelectedColour] = useState([]);
    const [selectedCepage, setSelectedCepage] = useState([]);
    const [selectedAppelation, setSelectedAppelation] = useState([]);
    const [locale, setLocale] = useState();

    const [refreshing, setRefreshing] = useState(true);
    // Selected Year filter
    const [searchValue, setSearchValue] = useState();

    useEffect(() => {
        const getProducts = async () => {
            client.getAllProducts().then((data) => {
                setProducts(data?.results);
                setFilteredList(data?.results);

                setLocale(localStorage.getItem("language"));
            });
            // }
        };
        getProducts();
    }, []);

    useEffect(() => {
        if (products.length > 0) {
            setRefreshing(false);
        }
    }, [products]);

    const searchTerms = [];
    products.map((product) => {
        if (searchTerms.indexOf(product.product_name) === -1) {
            searchTerms.push(product.product_name);
        }
    });

    const product_level_1 = [];
    products.map((product) => {
        if (product_level_1.indexOf(product.product_level_1) === -1) {
            product_level_1.push(product.product_level_1);
        }
    });

    const product_level_2 = [];
    products.map((product) => {
        if (product_level_2.indexOf(product.product_level_2) === -1) {
            product_level_2.push(product.product_level_2);
        }
    });

    const product_year = [];
    products.map((product) => {
        if (product_year.indexOf(product.product_year) === -1) {
            product_year.push(product.product_year);
        }
    });

    const product_level_3 = [];
    products.map((product) => {
        if (product_level_3.indexOf(product.product_level_3) === -1) {
            product_level_3.push(product.product_level_3);
        }
    });

    const product_level_4 = [];
    products.map((product) => {
        if (product_level_4.indexOf(product.product_level_4) === -1) {
            product_level_4.push(product.product_level_4);
        }
    });

    const filterByName = (filteredData) => {
        // Avoid filter for null value
        if (!searchValue) {
            return filteredData;
        }

        const filterini = filteredData.filter((product) =>
            product.product_name
                .toLowerCase()
                .includes(searchValue.toLowerCase())
        );
        return filterini;
    };

    const filterByMaison = (filteredData) => {
        // Avoid filter for empty string
        if (selectedMaison.length == 0) {
            return filteredData;
        }

        const filtered = filteredData.filter((lead) =>
            selectedMaison.includes(lead.product_level_1)
        );

        return filtered;
    };
    const filterByColour = (filteredData) => {
        // Avoid filter for empty string
        if (selectedColour.length == 0) {
            return filteredData;
        }

        const filtered = filteredData.filter((lead) =>
            selectedColour.includes(lead.product_year)
        );

        return filtered;
    };
    const filterByCepage = (filteredData) => {
        // Avoid filter for empty string
        if (selectedCepage.length == 0) {
            return filteredData;
        }

        const filtered = filteredData.filter((lead) =>
            selectedCepage.includes(lead.product_level_2)
        );

        return filtered;
    };
    const filterByAppelation = (filteredData) => {
        // Avoid filter for empty string
        if (selectedAppelation.length == 0) {
            return filteredData;
        }

        const filtered = filteredData.filter((lead) =>
            selectedAppelation.includes(lead.product_level_3)
        );

        return filtered;
    };

    const handleCepageChange = (cepage, event) => {
        if (!selectedCepage.includes(cepage)) {
            setSelectedCepage(selectedCepage.concat(cepage));
        } else {
            let filteredArray = selectedCepage.filter(
                (item) => item !== cepage
            );
            setSelectedCepage(filteredArray);
        }
    };

    const handleAppelationChange = (appelation, event) => {
        if (!selectedAppelation.includes(appelation)) {
            setSelectedAppelation(selectedAppelation.concat(appelation));
        } else {
            let filteredArray = selectedAppelation.filter(
                (item) => item !== appelation
            );
            setSelectedAppelation(filteredArray);
        }
    };

    // Toggle seletedYear state
    const handleSearchValue = (value) => {
        if (value != "") {
            setSearchValue(value);
            setSelectedMaison([]);
            setSelectedColour([]);
            setSelectedCepage([]);
            setSelectedAppelation([]);
            var clist = document.getElementsByTagName("input");
            for (var i = 0; i < clist.length; ++i) {
                clist[i].checked = false;
            }

            setBool(false);
        }
    };

    useEffect(() => {
        var filteredData = products;
        filteredData = filterByName(filteredData);
        filteredData = filterByMaison(filteredData);
        filteredData = filterByColour(filteredData);
        filteredData = filterByCepage(filteredData);
        filteredData = filterByAppelation(filteredData);
        setFilteredList(filteredData);
    }, [
        selectedMaison,
        selectedColour,
        selectedCepage,
        selectedAppelation,
        searchValue,
    ]);

    const autocompleteMatch = (input) => {
        if (input == "") {
            return [];
        }
        var reg = new RegExp(input.toLowerCase());
        return searchTerms.filter(function (term) {
            if (term.toLowerCase().match(reg)) {
                return term;
            }
        });
    };

    const [test_val, setVal] = useState([]);
    const [show_bool, setBool] = useState(false);

    const showResults = (val) => {
        if (val.length > 0) {
            setBool(true);
        } else {
            setBool(false);
        }
        let terms = autocompleteMatch(val);

        setVal(terms);
    };

    const updateValue = (text) => {
        if (text != "") {
            setBool(false);
            showResults(text);
            return (document.getElementById("q").value = text);
        }
    };

    const resetFilters = () => {
        setSearchValue();
        setSelectedMaison([]);
        setSelectedColour([]);
        setSelectedCepage([]);
        setSelectedAppelation([]);
        setFilteredList(products);
        var clist = document.getElementsByTagName("input");
        for (var i = 0; i < clist.length; ++i) {
            clist[i].checked = false;
        }

        setBool(false);
    };

    if (refreshing) {
        // Redirect to another page if user is not logged in
        return <Loader />;
    }

    return (
        <>
            <IntlProvider locale={locale} messages={messages[locale]}>
                <div className="flex flex-row h-screen overflow-y-auto">
                    <div className="w-full md:ml-20 xl:w-2/3 md:w-2/3 sm:w-2/3 px-15 text-left">
                        <div className="flex flex-col">
                            <div className="flex flex-col md:flex-row ml-5 md:mt-20">
                                <div>
                                    <h1 className="md:mb-12 text-xl text-black mt-5 ">
                                        <FormattedMessage
                                            id="product.product_catalogue"
                                            values={{ number: 1 }}
                                        />
                                    </h1>
                                </div>
                                <div className="md:ml-20 w-2/3">
                                    <form autoComplete="off" className="mt-5">
                                        <FormattedMessage
                                            id="product.searchplaceholder"
                                            defaultMessage="search"
                                        >
                                            {(placeholder) => (
                                                <input
                                                    type="search"
                                                    name="q"
                                                    id="q"
                                                    onChange={(e) =>
                                                        showResults(
                                                            e.target.value
                                                        )
                                                    }
                                                    onClick={(e) =>
                                                        handleSearchValue(
                                                            e.target.value
                                                        )
                                                    }
                                                    className={`mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-full text-sm shadow-sm placeholder-slate-400
                              focus:outline-none focus:border-callToAction focus:ring-1 focus:ring-callToAction
                              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                              invalid:border-pink-500 invalid:text-pink-600
                              focus:invalid:border-pink-500 focus:invalid:ring-pink-500`}
                                                    style={{ minWidth: 350 }}
                                                    onTouchCancel={resetFilters}
                                                    placeholder={placeholder}
                                                />
                                            )}
                                        </FormattedMessage>

                                        {show_bool && (
                                            <div
                                                id="result"
                                                className="text-black absolute z-40 text-sm border border-slate-300 rounded-b-md w-1/2"
                                                style={{
                                                    minWidth: 350,
                                                }}
                                            >
                                                <ul>
                                                    {" "}
                                                    {test_val.length &&
                                                        test_val.map((res) => (
                                                            <li
                                                                name="hand"
                                                                key={res}
                                                                onClick={() => {
                                                                    updateValue(
                                                                        res
                                                                    );
                                                                    handleSearchValue(
                                                                        res
                                                                    );
                                                                }}
                                                                className="mb-2 mt-2 pb-2 pt-2"
                                                            >
                                                                {res}
                                                            </li>
                                                        ))}
                                                </ul>
                                            </div>
                                        )}
                                    </form>
                                </div>
                            </div>

                            <div className="mb-5 relative mt-5">
                                <div className="container px-5 text-left gray-50 items-top justify-center grid xl:grid-cols-2 lg:grid-cols-1 gap-4">
                                    {filteredList.length &&
                                        filteredList.map((prod) => (
                                            <ProductDetails
                                                product={prod}
                                                key={prod.product_id}
                                                locale={locale}
                                            />
                                        ))}
                                </div>
                            </div>

                            <ScrollFilterButton />
                        </div>
                    </div>
                    <div className="w-full xl:w-1/5 md:w-1/5 sm:w-1/5 pl-20 sticky top-20 z-50 hidden md:block">
                        <div className=" flex flex-col float-left mt-40 text-left sticky top-40 z-50">
                            <span className="font-bold mt-30">
                                {/* {filteredList.length} Product(s) */}
                                {filteredList.length}{" "}
                                <FormattedMessage
                                    id="product.products"
                                    values={{ number: 1 }}
                                />
                            </span>

                            <div className="year-filter mt-5 text-black font-semibold text-sm mb-3">
                                <FormattedMessage id="product.type" />
                            </div>
                            <div className="text-black">
                                {product_level_2 &&
                                    product_level_2.map((level) => (
                                        <label
                                            key={level}
                                            className="custom_check"
                                        >
                                            {level}
                                            <input
                                                key={level}
                                                type="checkbox"
                                                onChange={(e) =>
                                                    handleCepageChange(level, e)
                                                }
                                            />{" "}
                                            <span
                                                key={level}
                                                className="checkmark"
                                            ></span>
                                        </label>
                                    ))}
                            </div>
                            <div className="year-filter font-semibold text-black text-sm mb-3">
                                <FormattedMessage id="product.family" />
                            </div>
                            <div className="text-black">
                                {product_level_1 &&
                                    product_level_1.map((level) => (
                                        <label
                                            key={level}
                                            className="custom_check"
                                        >
                                            {level}
                                            <input
                                                key={level}
                                                type="checkbox"
                                                onChange={(e) =>
                                                    handleAppelationChange(
                                                        level,
                                                        e
                                                    )
                                                }
                                            />{" "}
                                            <span
                                                key={level}
                                                className="checkmark"
                                            ></span>
                                        </label>
                                    ))}
                            </div>
                            <hr className="my-8 h-px bg-black border-0 black"></hr>
                            <label
                                className="text-xs font-bold"
                                onClick={resetFilters}
                            >
                                {" "}
                                x Remove all filters
                            </label>
                        </div>
                    </div>
                </div>
            </IntlProvider>
        </>
    );
};

export default Products;
