/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid"; // Import UUID generator

import FastAPIClient from "../../../client";
import config from "../../../config";
const client = new FastAPIClient(config);

import { FormattedMessage, IntlProvider } from "react-intl";

import { FiBell } from "react-icons/fi";

const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    const hours = String(d.getHours()).padStart(2, "0");
    const minutes = String(d.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
};

// Function to add minutes to a Date object
const addMinutes = (date, minutes) => {
    return new Date(date.getTime() + minutes * 60000);
};

const ReminderModal = ({ closeModal, initialEventData = {} }) => {
    const [newEventData, setNewEventData] = useState({
        meeting_id: uuidv4(),
        start_date: initialEventData.start
            ? formatDate(initialEventData.start)
            : null,
        end_date: initialEventData.start
            ? formatDate(addMinutes(new Date(initialEventData.start), 30))
            : null,
        user_id: "1", // Replace with the user's ID if applicable
        meeting_type_id: "1", // Meeting type ID should match backend's expectations
        is_visited: false, // Set to `true` if needed for your logic
        client_id: false, // Client ID; replace as per your application's data
    });

    const [clients, setClients] = useState([]);
    const [meetingTypes, setMeetingTypes] = useState([]);
    const [suggestions, setSuggestions] = useState([]); // Suggestions list
    const [inputValue, setInputValue] = useState(""); // Track input for search

    const handleChange = (e) => {
        const { name, value } = e.target;

        setNewEventData((prevData) => ({ ...prevData, [name]: value }));
    };

    useEffect(() => {
        fetchUserClients();
        fetchMeetingTypes();
    }, []);

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const fetchUserClients = () => {
        client.getUserClients().then((data) => {
            const sortedResults = data?.results.sort((a, b) => {
                // Move objects with 'alert' as true to the beginning
                return b.alert - a.alert; // b.alert - a.alert will move true values to the top
            });
            setClients(sortedResults);
        });
    };

    const fetchMeetingTypes = () => {
        client.getMeetingTypes().then((data) => {
            setMeetingTypes(data?.data.results || []);
        });
    };

    const handleSave = async () => {
        // Create the payload matching the backend's expected structure
        const payload = {
            meeting_id: newEventData.meeting_id,
            user_id: newEventData.user_id,
            meeting_type_id: newEventData.meeting_type_id, // Assuming category corresponds to `meeting_type_id`
            start_date: newEventData.start_date,
            end_date: newEventData.end_date,
            is_visited: newEventData.is_visited,
            client_id: newEventData.client_id,
        };

        try {
            // Make the POST request to create a new meeting

            await client.createMeeting(payload);

            setSuccess("Meeting created successfully!");

            // Automatically clear the error after 5 seconds
            setTimeout(() => {
                setSuccess(null);
            }, 5000);
            closeModal(); // Close the modal after saving
        } catch (error) {
            console.error("Failed to create meeting:", error);
            setError("There was a problem creating the meeting.");

            // Automatically clear the error after 5 seconds
            setTimeout(() => {
                setError(null);
            }, 5000);
        }
    };

    // Function to handle input change and show suggestions
    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        setSuggestions(
            clients.filter((client) =>
                client.client_name.toLowerCase().includes(value.toLowerCase())
            )
        );
    };

    // Function to handle selecting a suggestion
    const handleSelectSuggestion = (client) => {
        setNewEventData((prevData) => ({
            ...prevData,
            client_id: client.client_id,
            client_name: client.client_name,
        }));
        setInputValue(client.client_name);
        setSuggestions([]); // Hide suggestions after selection
    };

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-md shadow-md w-1/2">
                <div className="flex flex-row w-full items-center mb-4">
                    <div className="flex w-10 h-10 bg-gray-100 ml-2 rounded-full items-center justify-center">
                        <FiBell />
                    </div>
                    <div>
                        <h2 className="text-xl ml-4">Nouveau rappel</h2>
                    </div>
                </div>
                {error && (
                    <div className="fixed top-4 left-1/2 transform -translate-x-1/2 bg-red-500 text-white px-4 py-2 rounded">
                        {error}
                    </div>
                )}

                {success && (
                    <div className="fixed top-4 left-1/2 transform -translate-x-1/2 bg-green-500 text-white px-4 py-2 rounded">
                        {success}
                    </div>
                )}

                <form>
                    <div className="flex items-center mb-2">
                        <label className="w-1/3 pr-2">Date:</label>
                        <input
                            type="datetime-local"
                            name="start"
                            value={newEventData.start_date}
                            onChange={handleChange}
                            placeholder="Select start time"
                            className="border rounded-full w-full p-2"
                        />
                    </div>
                    <div className="flex items-center mb-2">
                        <label className="w-1/3 pr-2">Raison:</label>
                        <select
                            name="meeting_type_id"
                            value={newEventData.meeting_type_id}
                            onChange={handleChange}
                            className="border rounded-full w-full p-2"
                        >
                            <option value="" disabled>
                                Choisir le type
                            </option>
                            {meetingTypes.map((type) => (
                                <option
                                    key={type.meeting_type_id}
                                    value={type.meeting_type_id}
                                >
                                    {type.description}
                                </option>
                            ))}
                        </select>
                    </div>
                </form>

                <div className="flex justify-end mt-4">
                    <button
                        onClick={handleSave}
                        className="bg-black text-white px-4 py-2 rounded-full mr-2"
                    >
                        Sauvegarder
                    </button>
                    <button
                        onClick={closeModal}
                        className="bg-gray-300 text-gray-700 px-4 py-2 rounded-full"
                    >
                        Annuler
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ReminderModal;
