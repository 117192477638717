import React from "react";
import { FiShoppingBag } from "react-icons/fi";

const ClientOrders = ({ clientorders }) => {
    // Helper function to format the date
    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleDateString(); // Formats to "MM/DD/YYYY" by default or according to the user's locale
    };

    return (
        <div className="pt-5 ml-10 mr-10 w-5/6">
            <div className="flex flex-row w-full justify-between items-center mb-4">
                <div className="text-left text-sm font-semibold w-1/4"></div>
                <div className="text-left text-sm font-semibold w-1/6"></div>
                <div className="w-1/12"></div>

                <div className="text-sm  font-semibold text-center w-1/5">
                    ID produit
                </div>
                <div className="text-sm  font-semibold text-center w-1/5">
                    Date
                </div>
                <div className="text-sm  font-semibold text-center w-1/5">
                    Quantité
                </div>
            </div>
            {clientorders.map((product, index) => (
                <div
                    key={index}
                    className="flex flex-row w-full justify-between items-center pb-1 mt-1 border-b border-gray-200"
                >
                    <div className="text-left text-xs font-semibold w-1/3">
                        {product.product_name}
                    </div>
                    <div className="w-1/12">
                        {product.is_due && (
                            <div className="flex justify-center rounded-full w-3/4 bg-black text-gold text-xs text-center items-center align-center font-bold px-1 py-1 ">
                                <FiShoppingBag />
                            </div>
                        )}
                    </div>
                    <div className="w-1/12">
                        {product.is_promo && (
                            <div className="rounded-full w-3/4 bg-rose-500 text-white text-xs text-center font-bold px-1 py-1 ">
                                %
                            </div>
                        )}
                    </div>
                    <div className="text-sm  text-center w-1/5">
                        {product.product_id}
                    </div>

                    <div className="text-sm  text-center w-1/5">
                        {formatDate(product.last_order_date)}
                    </div>

                    <div className="text-sm  w-1/5 text-center">
                        {product.last_order_quantity}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ClientOrders;
