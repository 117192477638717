/*eslint-disable*/

import React, { useCallback, useEffect, useRef, useState } from "react";

import { AppLauncher } from "@capacitor/app-launcher";

import { FormattedMessage, IntlProvider } from "react-intl";

import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";

const messages = {
    en: messages_en,
    pt: messages_pt,
    fr: messages_fr,
};

const OpenMapsButton = ({ meetings }) => {
    // const [test, setTest] = useState([]);
    const [mobile, setMobile] = useState(false);

    const [locale, setLocale] = useState();

    useEffect(() => {
        // setRefreshing(false);
        setLocale(localStorage.getItem("language"));
        console.log(meetings)
    }, []);

    // Function to open Google Maps on mobile

    const openGoogleMaps = () => {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

        setMobile(isMobile);

        if ("geolocation" in navigator) {
            if (!isMobile) {
                const waypoints = meetings.map((meeting) => {
                    const { lat, lng } = meeting.location; // Extract latitude and longitude
                    return `${lat},${lng}`; // Format as "lat,long"
                });

                if (waypoints.length === 0) return;

                // Constructing the URL
                const origin = waypoints[0]; // You could set a specific origin if needed
                const destination = waypoints[waypoints.length - 1]; // Last meeting as the destination

                // Creating the waypoints string
                const waypointsString = waypoints.slice(1, -1).join("|"); // Skip first (origin) and last (destination)

                const mapsLink = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}&waypoints=${waypointsString}`;

                window.open(mapsLink, "_blank"); // Open the link in a new tab
            } else {
                const { value } = AppLauncher.canOpenUrl({
                    url: "https://www.google.com/maps/dir/?api=1&destination=48.7999656,2.2067029",
                });

                AppLauncher.openUrl({
                    url: "https://www.google.com/maps/dir/?api=1&destination=48.7999656,2.2067029",
                });
            }
        } else {
            // console.error("Geolocation is not supported by your browser");
            if (isMobile) {
                // If on mobile, use Google Maps app URL scheme
                const destination = "48.7999656,2.2067029"; // Replace with the actual destination
                const { value } = AppLauncher.canOpenUrl({
                    url: "https://www.google.com/maps/dir/?api=1&destination=48.7999656,2.2067029",
                });

                AppLauncher.openUrl({
                    url: "https://www.google.com/maps/dir/?api=1&destination=48.7999656,2.2067029",
                });
            } else {
                console.log("There has been a problem.");
            }
        }

        // Function to get the user's current location
        const getCurrentLocation = () => {};
    };

    // Call the function when the component mounts
    // openGoogleMaps();

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            <a
                className="transition text-xs text-black border-2 border-black hover:-translate-y-0 hover:scale-100 bg-white cursor-pointer hover:bg-black hover:text-white px-4 py-2 mx-auto mt-3 rounded-full"
                target="_blank" // Open the link in a new tab
                rel="noopener noreferrer" // Security best practice for opening links in a new tab
                onClick={openGoogleMaps}
            >
                <FormattedMessage
                    id="routing.open_in_maps"
                    values={{ number: 1 }}
                />
            </a>
        </IntlProvider>
    );
};

export default OpenMapsButton;
