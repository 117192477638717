import React, { createContext, useContext, useState, useEffect } from 'react';
import { OFFINE_OPERATE_DATA, OFFINE_OPERATE_API } from '../../sw/utils/constants'
import FastAPIClient from "../../client";
import config from "../../config";

// Localization
import { FormattedMessage, IntlProvider } from "react-intl";
import messages_en from "./translations/en.json";
import messages_fr from "./translations/fr.json";

const client = new FastAPIClient(config);
const OfflineContext = createContext();
// Language messages
const messages = {
  en: messages_en,
  fr: messages_fr,
};
const OfflineProvider = ({ children }) => {
  const [locale, setLocale] = useState();
  const [prefetchStatus, setPrefetchStatus] = useState('pending');
  const [disabled, setDisabled] = useState(false);
  const [offlineMeetings, setOfflineMeetings] = useState([]); // Meeting Data filtered by the meetingPayload
  const [meetingPayload, setMeetingPayload] = useState([]);// Offline: Meeting data stored in indexDB
  const [offlineRecommendations, setOfflineRecommendations] = useState([]);
  const [offlineReports, setOfflineReports] = useState([]);// Offline: edit reports data stored in indexDB
  const [offlineDeleteMeeting, setOfflineDeleteMeeting] = useState([]);// Offline: edit reports data stored in indexDB
  useEffect(() => {
    setLocale(localStorage.getItem("language"));
  }, [location]);
  useEffect(() => {
    // Listen for Service Worker messages
    const messageHandler = (event) => {
      if (event.data && event.data.type === 'PREFETCH_STATUS') {
        const { status } = event.data.payload
        console.log(event.data.payload, 'event.data.payloadevent.data.payload')
        setPrefetchStatus(status);
      } else if (event.data && event.data.type === OFFINE_OPERATE_DATA[JSON.stringify(OFFINE_OPERATE_API.CREATE_MEETING)].messageType) {
        // Process messages from the Service Worker for new meetings
        const updatedMeeting = event.data.payload;
        // Store cached data stored in indexxDB in offline state
        setMeetingPayload(prev => {
          return [...prev, updatedMeeting];
        })
      } else if(event.data && event.data.type === OFFINE_OPERATE_DATA[JSON.stringify(OFFINE_OPERATE_API.RECOMMENDATION)].messageType) {
        // Update the recommendation Settings for the current customer

        const updatedRecommendation = event.data.payload;
        const filterData = {
          ...JSON.parse(updatedRecommendation?.body),
          data_from_offline: true,
          createTime: updatedRecommendation.timestamp, // createTime: The timestamp when the clients's recommendation data was created
        }
        setOfflineRecommendations(prev => {
          const index = prev.findIndex(item => 
            item.client_id === filterData.client_id && item.task_id === filterData.task_id
          );
          if (index !== -1) {
            // If the item exists, replace it with the new data
            const updatedPrev = [...prev];
            updatedPrev[index] = filterData;
            return updatedPrev;
          } else {
            // If the item does not exist, add the new data to the array
            return [...prev, filterData];
          }
        })

      } else if(event.data && event.data.type === OFFINE_OPERATE_DATA[JSON.stringify(OFFINE_OPERATE_API.DELETE_MEETING)].messageType) {
        const updatedReport = event.data.payload;
        const meeting_id = updatedReport?.url.split('/')?.pop(); // 获取最后一部分
        console.log(updatedReport, 'updatedReport', meeting_id)
        // if(meeting_id)
        const idx = meetingPayload.findIndex(item => item.meeting_id === meeting_id);
        if(idx > -1) {
          setMeetingPayload(prev => {
            return prev.filter(item => item.meeting_id !== meeting_id);
          })
        } else {
          setOfflineDeleteMeeting(prev => {
            return [...prev, meeting_id];
          })
        }
      } else if(event.data && event.data.type === OFFINE_OPERATE_DATA[JSON.stringify(OFFINE_OPERATE_API.REPORTS)].messageType) {
        // Update the recommendation Settings for the current customer
        const updatedReport = event.data.payload;
        const filterData = {
          ...JSON.parse(updatedReport?.body),
          data_from_offline: true,
          createTime: updatedReport.timestamp, // createTime: The timestamp when the clients's recommendation data was created
        }
        setOfflineReports(prev => {
          const index = prev.findIndex(item => 
            item.client_id === filterData.client_id
          );
          if (index !== -1) {
            // If the item exists, replace it with the new data
            const updatedPrev = [...prev];
            updatedPrev[index] = filterData;
            return updatedPrev;
          } else {
            // If the item does not exist, add the new data to the array
            return [...prev, filterData];
          }
        })

      } else if (event.data && event.data.type === 'RELOAD_MEETING_LIST_COMPLETE') {
        // reloaded the page data
        clearOfflineMeetings();// clear the offlineMeetings state
        clearOfflineRecommendations(); // clear the offlineRecommendations state
        clearOfflineDeleteMeeting(); // clear the deleted meeting in offline mode
      } else if (event.data && event.data.type === 'DELETE_OFFLINE_MEETING_SUCCESS') {
        // Process the message from the Service Worker that successfully deleted the meeting data in indexDB
        const { key_path_id } = event.data.payload;
        // the meeting data is successfully stored in indexDB, 
        // delete the data from the local storage. key_path_id is the primary key and filters the data based on the primary key

        setMeetingPayload(prevMeetings => {
          console.log("prevMeetingskey_path_id", prevMeetings, key_path_id);
          return prevMeetings.filter(meeting => meeting.db_key_path !== key_path_id);
        });
      }
    };

    navigator?.serviceWorker?.addEventListener('message', messageHandler);
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    return () => {
      navigator?.serviceWorker?.removeEventListener('message', messageHandler);
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);


  // Filter the meetingPayload based on the meeting data format
  useEffect(() => {
    if(meetingPayload.length) {
      const meetings = meetingPayload.map((meeting) => {
        const newMeeting = JSON.parse(meeting.body);
        return {
          ...newMeeting, 
          createTime: meeting.timestamp, // createTime: The timestamp when the meeting data was created
          data_from_offline: true, // data_from_offline: Mark that the data was created from offline
          db_key_path: meeting.db_key_path,// db_key_path primary key id: This parameter is used to prepare for deleting meeting data from indexDB
          date: newMeeting.start_date,
        }
      })
      setOfflineMeetings(meetings)
    } else {
      setOfflineMeetings([])
    }
  }, [meetingPayload])

  // Clear the offlineMeetings state
  const clearOfflineMeetings = () => {
    setMeetingPayload([]);
  };

  // Clear the offlineRecommendations state
  const clearOfflineRecommendations = () => {
    setOfflineRecommendations([]);
  }
  // Clear the offlineRecommendations state
  const clearOfflineDeleteMeeting = () => {
    setOfflineDeleteMeeting([]);
  }
  // Clear the offlineRecommendations state
  const clearOfflineReports = () => {
    setOfflineReports([]);
  }

  // Send a message to notify the Service Worker to delete the corresponding data in IndexedDB
  const deleteOfflineMeeting = (key_path_id) => {
    if (navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({
        type: 'DELETE_OFFLINE_MEETING',
        payload: { 
          key_path_id: key_path_id, // key_path_id: The primary key of the data to be deleted
          storeName: OFFINE_OPERATE_DATA[OFFINE_OPERATE_API.CREATE_MEETING].storeName, // storeName: The name of the data store in indexDB
         }
      });
    }
  };

  const handlePrefetchClick = () => {
    if (navigator.serviceWorker.controller) {
      client.fetchUser().then((data) => {
        setPrefetchStatus('loading');
        const token = localStorage.getItem('token');
        navigator.serviceWorker.controller.postMessage({ 
          type: 'PREFETCH_ALL_DATA', 
          payload:{
            _token: token, 
            userInfo: data 
          }});
      }).catch(() => {
        setPrefetchStatus('error');
      })
    } else {
      console.warn('Service Worker is not active.');
    }
  };

  const handleOffline = () => {
    setDisabled(true)
  }
  const handleOnline = () => {
    setDisabled(false)
  }

  return (
    <OfflineContext.Provider value={{ offlineMeetings, offlineRecommendations, offlineReports,offlineDeleteMeeting,clearOfflineDeleteMeeting, clearOfflineMeetings, deleteOfflineMeeting, clearOfflineReports  }}>
      <IntlProvider locale={locale} messages={messages[locale]}>
        <div className='flex flex-row items-center justify-end pr-3 pt-3 pb-3'>
          <button className='transition text-black border-2 border-black hover:-translate-y-0 hover:scale-100 bg-white cursor-pointer hover:bg-black hover:text-white px-2 py-1 rounded-full' onClick={handlePrefetchClick} disabled={prefetchStatus === 'loading' || disabled}>
            {prefetchStatus === 'pending' ? <FormattedMessage
                id='offlineContext.pending'
            /> :
              prefetchStatus === 'loading' ? <FormattedMessage
              id={`offlineContext.loading`}
          /> :
              prefetchStatus === 'success' ? <FormattedMessage
              id={`offlineContext.success`}
          /> : <FormattedMessage
          id={`offlineContext.error`}
      />
          }
          </button>
        </div>
      </IntlProvider>
      {children}
    </OfflineContext.Provider>
  );
};

const useOfflineMeetings = () => useContext(OfflineContext);

export { OfflineProvider, useOfflineMeetings };