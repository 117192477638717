import React from "react";
import "./style.css";

// Reusable LargeWidgetButton component
const LargeWidgetButton = ({ icon, text, onClick, children }) => {
    const widgetStyle = `relative flex flex-col w-full h-full bg-white text-black border border-gray-200 rounded-md p-2`;
    const iconCircleStyle = `w-10 h-10 bg-gray-100 pl-0 pt-0 rounded-full flex items-center justify-center mr-4`; // Add margin-right for spacing
    const textStyle = `text-lg font-medium`; // Styling for text

    return (
        <button className={widgetStyle} onClick={onClick}>
            <div className="flex flex-row items-center">
                {/* Icon inside a circle */}
                <div className={iconCircleStyle}>{icon}</div>
                {/* Text aligned next to the icon */}
                <div className={textStyle}>{text}</div>
            </div>
            {/* Display additional children elements passed to the button */}
            {children && (
                <div
                    className="mt-4 w-full overflow-y-auto custom-scrollbar"
                    style={{ height: "450px" }}
                >
                    {children}
                </div>
            )}
        </button>
    );
};

export default LargeWidgetButton;
