import React from "react";

export class StepOne extends React.Component {
    constructor() {
        super();
        this.state = {
            firstName: "",
            lastName: "",
        };
        this.handleFirstNameChanged = this.handleFirstNameChanged.bind(this);
        this.handleLastNameChanged = this.handleLastNameChanged.bind(this);
    }

    handleFirstNameChanged(event) {
        this.setState({ firstName: event.target.value });
    }

    handleLastNameChanged(event) {
        this.setState({ lastName: event.target.value });
    }

    render() {
        return (
            <div
                className="flex flex-row items-center justify-center mb-10 mt-10 w-full "
                style={{ minHeight: 450 }}
            >
                <div className="flex xl:w-1/2 md:w-1/2 items-center justify-center">
                    {/* <img
                        className="rounded-md"
                        src="https://i0.wp.com/www.antoinemoueix.com/wp-content/uploads/2019/10/vignette-diapo-accueil-liversan.jpg?w=800&ssl=1"
                        width="500"
                    /> */}
                    <img
                        className="rounded-md"
                        src="https://actufood.fr/wp-content/uploads/2021/12/champagne-domaine-franck-pascal.png"
                        width="400"
                    />
                    {/* <img className='rounded-md' src=' https://www.heineken.com/media-eu/joqbtvt2/beertender.png?anchor=center&mode=crop&width=432&height=648&quality=85' width='300' /> */}
                </div>
                <div className="flex flex-col text-left xl:w-1/2 md:w-1/2 leading-7 text-base">
                    <span className="text-sm font-semibold text-client">
                        Outline
                    </span>
                    {/* <span className="text-lg font-semibold text-client">Discover Antoine Moueix</span> <br /> */}
                    <span className="text-lg font-semibold text-client">
                        Decouvrir Franck Pascal
                    </span>{" "}
                    <br />
                    <span>Après la formation, vous aurez appris sur:</span>
                    <br />
                    <div className="flex flex-row">
                        <span className="ml-5">- Le terroir</span>
                    </div>
                    <div className="flex flex-row">
                        <span className="ml-5">- Notre collaboration</span>
                    </div>
                    <div className="flex flex-row">
                        <span className="ml-5">- La Vinification</span>
                    </div>
                    <br />
                    {/* <span className="text-lg font-semibold text-client">Discover the Beertender</span> <br />
                    <span>
                        After the training you will have learned about:</span>
                    <br />
                    <div className='flex flex-row'>
                        <span className="ml-5">
                            - Utilisation
                        </span>
                    </div>
                    <div className='flex flex-row'>
                        <span className="ml-5">
                            - Features
                        </span>
                    </div>
                    <div className='flex flex-row'>
                        <span className="ml-5">
                           - Compatibility</span>
                    </div>
                    <div className='flex flex-row'>
                        <span className="ml-5">
                            - Installation</span>
                    </div>
                    < br /> */}
                    {/* <span className="text-client font-bold">
                        Complete the training and gain 10 points!
                    </span> */}
                    <span className="text-client font-bold">
                        Terminez la formation et gagnez 10 points !
                    </span>
                </div>
            </div>
        );
    }
}
