import React, { useState } from "react";

import {
    FiChevronDown,
    FiChevronUp,
    FiTruck,
    FiCreditCard,
} from "react-icons/fi";

const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString(); // Formats to "MM/DD/YYYY" by default or according to the user's locale
};

const OrderHistory = ({ orderhistory }) => {
    const [showProducts, setShowProducts] = useState({}); // Store visibility state for each order

    const toggleShowProducts = (orderId) => {
        setShowProducts((prevState) => ({
            ...prevState,
            [orderId]: !prevState[orderId], // Toggle the visibility for the specific order
        }));
    };

    const calculateTotal = (products) => {
        if (!Array.isArray(products)) return 0; // Ensure products is an array
        const total = products.reduce(
            (acc, prod) => acc + parseFloat(prod.total),
            0
        ); // Calculate total value
        return Math.round(total); // Round to nearest whole number
    };

    const getStatusColor = (status) => {
        // const numericStatus = parseInt(status, 10);
        switch (status) {
            case true:
                return "text-gold"; // Green for status 1
            case 2:
                return "text-gold"; // Orange for status 2
            case 3:
                return "text-red-500"; // Red for status 3
            default:
                return "text-gray-200"; // Gray for status 0 or other values
        }
    };

    return (
        <div className="pt-5 ml-10 mr-10 w-5/6">
            <div className="flex flex-row justify-between items-center mb-10">
                <div className="flex w-1/6 text-sm font-semibold">Date</div>
                <div className="flex w-1/6 text-sm font-semibold">ID</div>
                <div className="flex w-1/6 text-sm font-semibold text-right justify-end">
                    Total (€)
                </div>
                <div
                    className={`flex w-1/12 text-sm font-semibold justify-center `}
                >
                    Livraison
                </div>
                <div
                    className={`flex w-1/12 text-sm  font-semibold justify-center `}
                >
                    Paiement
                </div>
                <div
                    className={`flex w-1/12 text-sm  font-semibold justify-center `}
                ></div>
            </div>
            {orderhistory &&
                orderhistory.map((order, index) => (
                    <div
                        key={order.receipt_id || index}
                        className="list-wrapper mb-10 text-xs"
                    >
                        {order.type !== "Visite" && (
                            <div className="order-summary border-b border-gray-200">
                                {/* Total Order Value */}
                                <div className="flex flex-row justify-between items-center">
                                    <div className="flex w-1/6 text-sm">
                                        {formatDate(order.receipt_date)}
                                    </div>
                                    <div className="flex w-1/6 text-sm">
                                        {order.receipt_id}
                                    </div>
                                    <div className="flex w-1/6 text-sm text-right justify-end">
                                        {calculateTotal(
                                            order.products.products
                                        )}
                                        €
                                    </div>
                                    <div
                                        className={`flex w-1/12 text-sm  justify-center ${getStatusColor(
                                            order.is_delivered
                                        )}`}
                                    >
                                        <FiTruck />
                                    </div>
                                    <div
                                        className={`flex w-1/12 text-sm  justify-center ${getStatusColor(
                                            order.is_payed
                                        )} `}
                                    >
                                        <FiCreditCard />
                                    </div>
                                    <button
                                        className="flex w-1/12 dropdown-toggle text-lg bg-white text-black rounded-md px-4 py-2"
                                        onClick={() =>
                                            toggleShowProducts(
                                                order.receitp_id || index
                                            )
                                        }
                                    >
                                        {showProducts[
                                            order.receipt_id || index
                                        ] ? (
                                            <FiChevronUp />
                                        ) : (
                                            <FiChevronDown />
                                        )}
                                    </button>
                                </div>

                                {/* Product List - Visible only when the dropdown is clicked */}
                                {showProducts[order.id || index] && (
                                    <div className="product-list text-xs">
                                        <div className="flex flex-row items-center relative w-full text-xs font-semibold">
                                            <div className="flex flex-row w-full justify-between">
                                                <div className="w-3/6 text-left">
                                                    Produit
                                                </div>
                                                <div className="w-1/6 text-left">
                                                    ID
                                                </div>
                                                <div className="w-1/6 text-right">
                                                    Prix
                                                </div>
                                                <div className="w-1/6 text-right">
                                                    Quantité
                                                </div>
                                                <div className="w-1/6 text-right">
                                                    Total
                                                </div>
                                            </div>
                                        </div>

                                        {Array.isArray(
                                            order.products.products
                                        ) &&
                                            order.products.products.map(
                                                (prod) => (
                                                    <div
                                                        key={prod.product_id}
                                                        className="flex flex-row items-center relative w-full mt-1 "
                                                    >
                                                        <div className="flex flex-row w-full justify-between border-t border-grey-200 pt-1">
                                                            <div className="w-3/6 text-left">
                                                                {prod.title}
                                                            </div>
                                                            <div className="w-1/6 text-left">
                                                                {
                                                                    prod.product_id
                                                                }
                                                            </div>
                                                            <div className="w-1/6 text-right">
                                                                {parseFloat(
                                                                    prod.price
                                                                ).toFixed(2)}
                                                                €
                                                            </div>
                                                            <div className="w-1/6 text-right">
                                                                {prod.quantity}
                                                            </div>
                                                            <div className="w-1/6 text-right">
                                                                {parseFloat(
                                                                    prod.total
                                                                ).toFixed(2)}
                                                                €
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                    </div>
                                )}
                            </div>
                        )}

                        {order.type === "Visite" && (
                            <div className="flex flex-row justify-between items-center border-b border-gray-200 text-left">
                                <div className="text-xs font-semibold">
                                    {order.created_at}
                                </div>
                                <div className="text-xs font-semibold">
                                    {order.type}
                                </div>
                                <div className="text-xs font-semibold">
                                    {/* {calculateTotal(order.products)}€ */}
                                </div>
                                <div
                                    className={`text-xs font-semibold  ${getStatusColor(
                                        order.delivery_status
                                    )}`}
                                >
                                    {/* <FiTruck /> */}
                                </div>
                                <div
                                    className={`text-xs font-semibold ${getStatusColor(
                                        order.payment_status
                                    )} `}
                                >
                                    {/* <FiCreditCard /> */}
                                </div>
                                <button
                                    className="dropdown-toggle text-lg bg-white text-black rounded-md px-4 py-2"
                                    onClick={() =>
                                        toggleShowProducts(order.id || index)
                                    }
                                >
                                    {showProducts[order.id || index] ? (
                                        <FiChevronUp />
                                    ) : (
                                        <FiChevronDown />
                                    )}
                                </button>
                            </div>
                        )}
                    </div>
                ))}
        </div>
    );
};

export default OrderHistory;
