import React from "react";

export class StepFour extends React.Component {
    constructor() {
        super();
        this.state = {
            checked: "",
        };
        this.handleCheckedChanged = this.handleCheckedChanged.bind(this);
    }

    handleCheckedChanged(event) {
        this.setState({ checked: event.target.checked });
    }

    render() {
        return (
            <div
                className="flex flex-row items-center justify-center mb-10 mt-10 w-full"
                style={{ minHeight: 450 }}
            >
                <div className="flex xl:w-1/2 md:w-1/2 items-center justify-center">
                    <img
                        className="rounded-md"
                        src="https://www.thespruceeats.com/thmb/3o0c9bNCt-xAsGpIFVHk37oRoCY=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/senior-mans-hands-harvesting-grapes-from-vine-556415823-58339d1f5f9b58d5b1bf918a.jpg"
                        width="500"
                    />
                    {/* <img className="rounded-md" src=' https://www.heineken.com/media-eu/joqbtvt2/beertender.png?anchor=center&mode=crop&width=432&height=648&quality=85' width='300' /> */}
                </div>
                <div className="flex flex-col text-left xl:w-1/2 md:w-1/2 leading-7 text-base">
                    <span className="text-base font-semibold text-client">
                        Question
                    </span>{" "}
                    <br />
                    {/* <span className="text-lg font-semibold text-client">
How do vatting periods vary?</span> */}
                    <span className="text-lg font-semibold text-client">
                        Sur combien de communes s’étend le vignoble de notre
                        partenaire Franck Pascal?
                    </span>
                    <br />
                    {/* <label className="container">Between 5 and 10 days<input type="checkbox" /> <span className="checkmark"></span></label>
							<label className="container">Between 10 and 20 days<input type="checkbox" /> <span className="checkmark"></span></label>
							<label className="container">Between 15 and 25 days<input type="checkbox" /> <span className="checkmark"></span></label>
							<label className="container">Between 15 and 30 days<input type="checkbox" /> <span className="checkmark"></span></label> */}
                    <label className="container">
                        3<input type="checkbox" />{" "}
                        <span className="checkmark"></span>
                    </label>
                    <label className="container">
                        4<input type="checkbox" />{" "}
                        <span className="checkmark"></span>
                    </label>
                    <label className="container">
                        5<input type="checkbox" />{" "}
                        <span className="checkmark"></span>
                    </label>
                    <label className="container">
                        6<input type="checkbox" />{" "}
                        <span className="checkmark"></span>
                    </label>
                    {/* <span className="text-lg font-semibold text-client">
What kegsize is the beertender made for?</span>
 <br />
<label className="container">2.5 liter<input type="checkbox" /> <span className="checkmark"></span></label>
							<label className="container">5 liter<input type="checkbox" /> <span className="checkmark"></span></label>
							<label className="container">7.5 liter<input type="checkbox" /> <span className="checkmark"></span></label>
							<label className="container">10 liter<input type="checkbox" /> <span className="checkmark"></span></label> */}
                    <a className="transition ease-in-out rounded-md  delay-150 hover:-translate-y-0 hover:scale-100  bg-callToAction cursor-pointer hover:bg-fade text-white font-bold text-xs px-4 py-2 mx-auto mt-3">
                        Submit
                    </a>
                </div>
            </div>
        );
    }
}
