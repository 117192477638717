import React from "react";

function BackButton({ handleGoBack }) {
    return (
        <div>
            <button
                onClick={handleGoBack}
                className="bg-gray-200 p-1 rounded-full mt-5 ml-4 xl:ml-14 flex items-center justify-center"
                style={{ width: "2rem", height: "2rem" }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="black"
                    className="bi bi-arrow-left"
                    viewBox="0 0 16 16"
                    style={{ width: "1.5rem", height: "1.5rem" }}
                >
                    <path
                        fillRule="evenodd"
                        d="M6.354 11.354a.5.5 0 0 1 0-.708L2.707 8l3.647-3.646a.5.5 0 1 1 .708.708L4.707 8l2.354 2.354a.5.5 0 0 1-.708.708z"
                    />
                    <path
                        fillRule="evenodd"
                        d="M3.5 8a.5.5 0 0 1 .5-.5h7.793a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.5-.5z"
                    />
                </svg>
            </button>
        </div>
    );
}

export default BackButton;
