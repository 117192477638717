
export const prefix = `/api/v1`
// need to cache response data api
export const CACHE_RESPONSE_API = {
  ME: prefix + '/auth/me',
  CLIENTS: prefix + '/clients/full_details/',
  MEETINGS: prefix + '/meetings/',
  PRODUCT: prefix + '/product/',
  MEETING_TYPES: prefix +'/meetings/meeting_types/',
  LAYOUT: prefix + '/layout/',
  REPORTS_LAYOUT: prefix + '/reports/layout/',
  USER: prefix + '/user/',
  // RECO_PRODUCTS: prefix + '/reco/products/',
  // RECOMMENDATION: prefix + '/recommendation/',
  // RECEIPT: prefix + '/receipt/',
  // PRODUCT_CLIENT_ORDERS: prefix + '/product_client_orders/',
  // CLIENT_STATS: prefix + '/client_stats/',
  // REPORTS: prefix + '/reports/',
}

/**
 * cache response map data
 * @key {string} api url
 * @storeName {string} indexDB ObjectStore Name
 * @patterns  {RegExp} cache response url pattern
 */
export const CACHE_RESPONSE_DATA = {
  [CACHE_RESPONSE_API.ME]: {
    storeName: 'me',
    patterns: new RegExp(`^${prefix}/auth/me(?:\\?.*)?$`),
  },
  [CACHE_RESPONSE_API.CLIENTS]: {
    storeName: 'clients',
    patterns: new RegExp(`^${prefix}/clients/full_details/\\?(?=.*user_id=)(?:[^?#]*)$`),
  },
  [CACHE_RESPONSE_API.MEETINGS]: {
    storeName: 'meetings',
    patterns: new RegExp(`^${prefix}/meetings/(?:\\d+|\\?.*)?$`),
  },
  [CACHE_RESPONSE_API.PRODUCT]: {
    storeName: 'product',
    patterns: new RegExp(`^${prefix}/product/(?:\\d+|\\?.*)?$`),
  },
  // [CACHE_RESPONSE_API.RECO_PRODUCTS]: {
  //   storeName: 'reco_products',
  //   patterns: new RegExp(`^${prefix}/reco/products/(?:\\d+|\\?.*)?$`),
  // },
  [CACHE_RESPONSE_API.MEETING_TYPES]: {
    storeName: 'meeting_types',
    patterns: new RegExp(`^${prefix}/meetings/meeting_types/(?:\\d+|\\?.*)?$`),
  },
  [CACHE_RESPONSE_API.LAYOUT]: {
    storeName: 'layout',
    // patterns: new RegExp(`^${prefix}/layout/(?:\\d+|\\?.*)?$`),
  },
  // [CACHE_RESPONSE_API.RECOMMENDATION]: {
  //   storeName: 'recommendation',
  //   patterns: new RegExp(`^${prefix}/recommendation/(?:\\d+|\\?.*)?$`),
  // },
  // [CACHE_RESPONSE_API.RECEIPT]: {
  //   storeName: 'receipt',
  //   patterns: new RegExp(`^${prefix}/receipt/(?:\\d+|\\?.*)?$`),
  // },
  // [CACHE_RESPONSE_API.PRODUCT_CLIENT_ORDERS]: {
  //   storeName: 'product_client_orders',
  //   patterns: new RegExp(`^${prefix}/product_client_orders/(?:\\d+|\\?.*)?$`),
  // },
  [CACHE_RESPONSE_API.REPORTS_LAYOUT]: {
    storeName: 'reports_layout',
    // patterns: new RegExp(`^${prefix}/reports/layout/(?:\\d+|\\?.*)?$`),
  },
  // [CACHE_RESPONSE_API.CLIENT_STATS]: {
  //   storeName: 'client_stats',
  //   patterns: new RegExp(`^${prefix}/client_stats/(?:\\d+|\\?.*)?$`),
  // },
  // [CACHE_RESPONSE_API.REPORTS]: {
  //   storeName: 'reports',
  //   patterns: new RegExp(`^${prefix}/reports/(?:\\d+|\\?.*)?$`),
  // }
  [CACHE_RESPONSE_API.USER]: {
    storeName: 'user',
  },
}

//  offline operate api
export const OFFINE_OPERATE_API = {
  CREATE_MEETING: {
    path: prefix + '/meetings/',
    method: 'POST',
  },
  RECOMMENDATION: {
    path: prefix + '/recommendation/',
    method: 'PUT',
  },
  REPORTS: {
    path: prefix + '/reports/',
    method: 'POST',
  },
  DELETE_MEETING: {
    path: prefix + '/meetings/',
    method: 'DELETE',
  },
}

/**
 * offline operate data
 * @key {string} api url
 * @storeName {string} indexDB ObjectStore Name
 * @patterns  {RegExp} cache response url pattern
 * @method {string} request method
 * @messageType {string} message type
 */
export const OFFINE_OPERATE_DATA = {
  [JSON.stringify(OFFINE_OPERATE_API.CREATE_MEETING)]: {
    method: 'POST',
    storeName: 'create_meeting',
    messageType: 'update_meeting_list',
    keyPath: 'id'
  },
  [JSON.stringify(OFFINE_OPERATE_API.RECOMMENDATION)]: {
    storeName: 'edit_recommendation',
    method: 'PUT',
    messageType: 'update_recommendation',
    patterns: new RegExp(`^${prefix}/recommendation/(?:\\d+|\\?.*)?$`),
    keyPath: 'url', // The url is used as the key path to solve the problem that a customer may modify the recommended information for multiple times. Only the last modified information is saved and submitted to the server
  },
  [JSON.stringify(OFFINE_OPERATE_API.REPORTS)]: {
    storeName: 'edit_reports',
    method: 'POST',
    messageType: 'update_reports',
    keyPath: 'client_id'
  },
  [JSON.stringify(OFFINE_OPERATE_API.DELETE_MEETING)]: {
    method: 'DELETE',
    storeName: 'delete_meeting',
    messageType: 'delete_meeting',
    patterns: new RegExp(`^${prefix}/meetings/(?:\\d+|\\?.*)?$`),
    keyPath: 'url',
  }
}