import React from "react";

function PopupModal({ onCloseBtnPress, modalTitle, children }) {
    return (
        <div className=" container flex justify-center w-full">
            <div className="inset-0 flex items-center justify-center bg-gray-700 bg-opacity-80 absolute z-50">
                <div
                    className="rounded max-w-sm p-6 bg-white"
                    style={{
                        maxHeight: 900,
                        minHeight: 650,
                        minWidth: 1340,
                        overflowY: "auto",
                        overflowX: "auto",
                    }}
                >
                    <div className="flex items-center justify-between grow">
                        <h3 className="text-xl font-bold uppercase text-black ml-5 mt-10">
                            {modalTitle}
                        </h3>
                        <div className="cursor-pointer mt-10">
                            <svg
                                onClick={onCloseBtnPress}
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="rgb(120 120 120)"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default PopupModal;
